import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService as Auth0Service, IdToken } from '@auth0/auth0-angular';
import { HttpHeaderNames } from '@Core/Lib/Enums/http-header-names';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoggingService } from '@Services/logging-service';
import _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public authLoaded: boolean;

    private _spaName: string;
    private _isRefreshing: boolean;
    private baseUrl: string;

    private authContext: AuthContext;
    private _observable: Subject<AuthContext>;
    private _logOutRequest: Observable<any>;

    constructor(private http: HttpClient,
        private logger: LoggingService,
        private auth0Service: Auth0Service
    ) {
        this.baseUrl = environment.applicationEndpoint + (environment.requireAPIM ? '/Auth' : '/Admin')
    }

    public get LogoutURL() {
        return `${this.baseUrl}/Users/Logout`;
    }

    public get CurrentUserURL() {
        return `${this.baseUrl}/CurrentUser`;
    }

    public get InsurityBITokenURL() {
        return `${this.baseUrl}/InsurityBIToken`;
    }

    public get AdminTenantTokenURL() {
        return `${this.baseUrl}/Users/TenantAreaToken`;
    }

    public set spaName(name: string) {
        this._spaName = name;
    }
    public get spaName() {
        return this._spaName;
    }

    public get BearerToken() {
        return this.authContext.bearerToken;
    }

    public get isAuthenticated$(): Observable<boolean> {
        return this.authContext$.pipe(switchMap((authContext) => {
            if (!authContext?.expiration) {
                this.logger.logInfo("User's token does not have an expiration date.");
                return of(false);
            }
            if (new Date() < this.authContext.expiration) {
                return of(true);
            } else {
                this.logger.logInfo("User's token has expired. ");
                return of(false)
            }
        }));
    }

    public get authContext$(): Observable<AuthContext> {
        if (this.authContext) {
            return of(this.authContext);
        }

        if (this._observable) {
            return this._observable;
        }

        this._observable = new Subject<AuthContext>();

        this.getCurrentUser();

        return this._observable;
    }

    // #region Auth0 Observable Wrappers

    public get idTokenClaims$(): Observable<IdToken> {
        return this.auth0Service.idTokenClaims$;
    }

    public get error$(): Observable<Error> {
        return this.auth0Service.error$;
    }

    // #endregion Auth0 Observable Wrappers



    public getSubscriptionKey(): string {
        return this.authContext?.subscription;
    }

    public getIdentityKey() {
        return this.authContext?.identityKey;
    }

    public getTenantKey() {
        return this.authContext?.tenantKey;
    }

    public getAreaKey() {
        return this.authContext?.areaKey
    }

    public isProgramManager(): boolean {
        return !!this.authContext?.managedPrograms?.length;
    }

    public getManagedPrograms(): string[] {
        return this.authContext?.managedPrograms;
    }

    public getExpiration(): Date {
        return this.authContext?.expiration;
    }

    public getAvailableTenants(): string[] {
        return this.authContext?.tenants;
    }

    public getAvailableAreasForCurrentTenant(): string[] {
        return this.authContext?.areas;
    }

    public login() {
        this.logout(false); // make sure any existing cookie is gone before the auth http-interceptor picks it up
        this.auth0Service.loginWithRedirect({});
    }

    public generateClaims(bearerToken): Observable<Response> {
        const headers: HttpHeaders = new HttpHeaders()
            .set(HttpHeaderNames.ContentType, 'application/json')
            .set(HttpHeaderNames.Authorization, "bearer " + bearerToken);

        let request = this.http.get<any>(`${this.baseUrl}/Users/Login?browserMode=true`,
            { headers: headers, withCredentials: true }) // tell the browser to accept the set-cookie header
            .pipe(share());

        request.subscribe(response => {
            let content = response.Content;
            this.setValues(response.Content);
        });

        return request;
    }

    public logout(redirect: boolean = true): Observable<Response> {
        if (this._logOutRequest) {
            return this._logOutRequest;
        }

        let request = this._logOutRequest = this.http.post<any>(this.LogoutURL, null).pipe(share());

        request.subscribe({
            next: response => {
                this.authContext = null;
            }, 
            error: error => {
                if (error.status != 401) {
                    this.logger.logException(error, SeverityLevel.Error);
                }
            }, 
            complete: () => {
                if (redirect) {
                    this.auth0Logout();
                }      
            }
        });

        return request;
    }

    public auth0Logout() {
        this.auth0Service.logout({ logoutParams: { returnTo: environment.baseUrl } });
    }

    public getCurrentUser(): Observable<Response> {
        let request = this.http.get<any>(this.CurrentUserURL, {
            // For any other request, the interceptor would add this option, 
            // but we have to explicitly specify it here because
            // this is used by the interceptor to add the option to all _other_ requests.
            withCredentials: true,
        }).pipe(share());

        request.subscribe({
            next: response => {
                let content = response.Content;
                this.setValues(content);
                this.authLoaded = true;
            }, 
            error: error => {
                this.authLoaded = true;
                this.auth0Logout();
            }
        });

        return request;
    }

    public insurityBIToken(): Observable<Response> {
        let request = this.http.get<any>(this.InsurityBITokenURL, {
            withCredentials: true,
        }).pipe(share());

        request.subscribe({
            next: response => { }, 
            error: error => { this.auth0Logout(); }
        });

        return request;
    }

    public getAdminTenantAreaToken(optionalParameters: {
        tenantKey: string,
        areaKey?: string,
    }): Observable<Response> {

        let params = new HttpParams();
        params = params.set('tenantKey', optionalParameters.tenantKey);

        if (optionalParameters?.areaKey != null)
            params = params.set('areaKey', optionalParameters.areaKey);

        // Make the request
        let request = this.http.get<any>(this.AdminTenantTokenURL, {
            params,
            withCredentials: true,
        }).pipe(share());

        request.subscribe({ 
            next: () => {}, 
            error: () => { 
                //this.auth0Logout(); 
            }
        });

        return request;
    }

    public refresh() {
        if (this._isRefreshing) {
            // The request has already been sent.
            return;
        }

        this._isRefreshing = true;

        console.log("Refreshing Token");

        let request = this.http.get<any>(`${this.baseUrl}/Users/Refresh?browserMode=true`).subscribe({
            next: response => {
                let content = response.Content;
                this.setValues(response.Content);
                this._isRefreshing = false;
            }, 
            error: err => {
                this._isRefreshing = false;
                if (err instanceof HttpErrorResponse) {
                    const resp: HttpErrorResponse = err as HttpErrorResponse;
                    if (resp.status == 403) {
                        this.logout();
                    }
                }
            }
        });
    }

    public selectTenant(tenantKey: string) {
        const headers: HttpHeaders = new HttpHeaders().set(HttpHeaderNames.ContentType, 'application/json');

        let request = this.http.post<any>(`${this.baseUrl}/Users/SelectTenant?browserMode=true`, { TenantKey: tenantKey }, {
            headers: headers,
            withCredentials: true,
        }).pipe(share());

        request.subscribe(response => {
            let content = response.Content;
            this.setValues(content);
        })

        return request;
    }

    public selectArea(areaKey: string) {
        let request = this.http.post<any>(`${this.baseUrl}/Users/SelectArea?browserMode=true`, { areaKey })
            .pipe(share());

        request.subscribe(response => {
            let content = response.Content;
            this.setValues(content);
        });

        return request;
    }

    public recoverPassword(email: string) {
        const headers: HttpHeaders = new HttpHeaders().set(HttpHeaderNames.ContentType, 'application/json');
        const request = this.http.post<any>(`${this.baseUrl}/Users/SendResetPassword`, JSON.stringify(email), {
            headers: headers
        }).pipe(share());

        request.subscribe(response => {
        });

        return request;
    }

    private setValues(content: any) {
        if (content) {
            this.authContext = {
                identityKey: content.identityKey,
                tenantKey: content.tenantKey,
                areaKey: content.areaKey,
                subscription: content.subscription,
                expiration: content.expires ? new Date(content.expires) : null,
                managedPrograms: AuthService.calculateManagedPrograms(content.managedPrograms),
                tenants: content.tenants,
                areas: content.areas,
                bearerToken: "cookie"
            };
        }
        if (this._observable) {
            this._observable.next(this.authContext);
            this._observable.complete();
            this._observable = null;
        }
    }

    public static calculateManagedPrograms(claim: string): string[] {
        if (claim) {
            let managedPrograms = [];

            let programs = claim.split('|');
            for (let program of programs) {
                if (program.includes('*'))
                    managedPrograms.push(program.replace('*', ''));
            }
            return managedPrograms;
        }
        return [];
    }

    public resetUserAuthentication(userId: string) {
        const headers: HttpHeaders = new HttpHeaders().set(HttpHeaderNames.ContentType, 'application/json');
        const request = this.http.put(`${this.baseUrl}/Users/` + userId + '/ResetAuth', null, {
            headers: headers
        }).pipe(share());

        request.subscribe(response => { });
        return request;
    }

}

export interface AuthContext {
    expiration: Date;
    subscription: string;
    identityKey: string;
    tenantKey: string;
    areaKey: string;
    managedPrograms: string[];
    tenants: string[];
    areas: string[];
    bearerToken: string;
}

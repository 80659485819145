import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType } from '@Core/Lib/Enums/notification-type-enum';
import { SmAssignableBadgeVM } from '@Shared/Components/sm-assignable-badge/sm-assignable-badge.viewmodel';
import { Observable } from 'rxjs';

@Component({
    selector: 'sm-global-notifications-card',
    templateUrl: './sm-global-notifications-card.component.html',
    styleUrls: ['./sm-global-notifications-card.component.scss']
})

export class SmGlobalNotificationsCardComponent {
    @Input() vm: NotificationCardVM;

    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onClickLink: EventEmitter<NotificationCardVM> = new EventEmitter();

    @HostBinding('class.read') get readClass() { return this.vm.IsRead; }
    @HostBinding('class.deleted') get deletedClass() { return this.vm.IsMarkedForDelete; }
    
    public isCollapsed: boolean = true;
    public needsShowMoreShowLess: boolean = true;

    constructor(private router: Router) {}

    ngOnInit() {
        this.needsShowMoreShowLess = this.vm.Description?.length > 60;
        this.isCollapsed = this.vm.IsRead;
    }

    onDeleteNotification(): void {
        this.vm.IsMarkedForDelete = true;
    }

    onUndoDelete(): void {
        this.vm.IsMarkedForDelete = false;
    }

    markAsRead(): void {
        this.vm.IsRead = true;
    }

    toggleDescription(): void {
        this.isCollapsed = !this.isCollapsed;
    }

    handleRedirect(entityRoute: string): void {
        if (this.vm.IsRead) {
            this.onClose.emit();
            this.router.navigate([entityRoute]);

            return;
        }

        this.markAsRead();
        this.onClose.emit(this.vm);
        this.router.navigate([entityRoute])
    }
}

export interface NotificationSubtitleVM {
    EntityType: string,
    DisplayEntityType?: string,
    EntityText: string,
    IsLink: boolean
}

export enum NotificationDomain {
    Account = 'Account',
    Tenant = 'Tenant'
}

export interface NotificationCardVM {
    Id: string,
    Domain: NotificationDomain,
    NotificationType: NotificationType,
    Message: string,
    Description: string,
    Subtitles: NotificationSubtitleVM[],
    OriginatorBadge: Observable<SmAssignableBadgeVM>,
    CreatedOnDate: Date,
    ContextIds: { [key: string]: string},
    IsRead: boolean,
    IsMarkedForDelete: boolean    
}

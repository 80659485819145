import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import _ from 'lodash';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';
import { ITypedServiceResponse } from '@Core/Lib/model';

import { DataContext } from '@Core/Lib/Contexts/data-context';
import * as Models from '@Core/CodeGen/Models/area.models';

class Response extends HttpResponse<any> { }

@Injectable()
export class DocumentsService extends BaseService {

    public static baseUrl: string = "";

    constructor(protected http: HttpClient,
                protected globalMessages: GlobalMessages,
                protected loggingService: LoggingService,
                protected loadingService: LoadingService) {
        super(globalMessages, loggingService, loadingService);
        DocumentsService.baseUrl = BaseService.baseUrl + "/Documents/";
    }

    public RetrieveCorrespondence(contexts: DataContext[] | DataContext, accountId: string, correspondenceId: string): Observable<ITypedServiceResponse<Models.Correspondence>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountId}/Correspondence/{correspondenceId}";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{correspondenceId}", correspondenceId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Correspondence, request, context);
        });
        return request;
    }

    public RetrieveCorrespondenceAssociatedWithACorrespondenceTask(contexts: DataContext[] | DataContext, accountId: string, taskId: string, workflowSetId: string): Observable<ITypedServiceResponse<Models.Correspondence>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountId}/Correspondence";
        url = url.replace("{accountId}", accountId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('taskId', taskId);
        params = params.set('workflowSetId', workflowSetId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Correspondence, request, context);
        });
        return request;
    }

    public UpdateACorrespondence(body: any, contexts: DataContext[] | DataContext, accountId: string, correspondenceId: string): Observable<ITypedServiceResponse<Models.Correspondence>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountId}/Correspondence/{correspondenceId}";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{correspondenceId}", correspondenceId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Correspondence, request, context);
        });
        return request;
    }

    public LockCorrespondence(body: any, contexts: DataContext[] | DataContext, accountId: string, correspondenceId: string): Observable<ITypedServiceResponse<Models.Correspondence>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountId}/Correspondence/{correspondenceId}/Lock";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{correspondenceId}", correspondenceId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Correspondence, request, context);
        });
        return request;
    }

    public UnlockCorrespondence(body: any, contexts: DataContext[] | DataContext, accountId: string, correspondenceId: string): Observable<ITypedServiceResponse<Models.Correspondence>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountId}/Correspondence/{correspondenceId}/Unlock";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{correspondenceId}", correspondenceId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Correspondence, request, context);
        });
        return request;
    }

    public ComposeCorrespondence(body: any, contexts: DataContext[] | DataContext, accountId: string, correspondenceId: string, workflowSetId: string): Observable<ITypedServiceResponse<Models.Correspondence>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountId}/Correspondence/{correspondenceId}/Compose";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{correspondenceId}", correspondenceId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('workflowSetId', workflowSetId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Correspondence, request, context);
        });
        return request;
    }

    public SendCorrespondence(body: any, contexts: DataContext[] | DataContext, accountId: string, correspondenceId: string, workflowSetId: string): Observable<ITypedServiceResponse<Models.Correspondence>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountId}/Correspondence/{correspondenceId}/Send";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{correspondenceId}", correspondenceId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('workflowSetId', workflowSetId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Correspondence, request, context);
        });
        return request;
    }

    public ListDocuments(contexts: DataContext[] | DataContext, accountIdOrCode: string,
        optionalParameters: {
            documentCategoryId?: string,
            documentTag?: string
        }): Observable<ITypedServiceResponse<Models.Document>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.documentCategoryId != null)
            params = params.set('documentCategoryId', optionalParameters.documentCategoryId);
        if (optionalParameters?.documentTag != null)
            params = params.set('documentTag', optionalParameters.documentTag);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Document, request, context);
        });
        return request;
    }

    public RetrieveADocument(contexts: DataContext[] | DataContext, accountIdOrCode: string, documentId: string): Observable<ITypedServiceResponse<Models.Document>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Document, request, context);
        });
        return request;
    }

    public GetTheTemporaryDocumentUrl(accountIdOrCode: string, documentId: string,
        optionalParameters: {
            urlType?: string
        }): Observable<Response> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}/Url";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.urlType != null)
            params = params.set('urlType', optionalParameters.urlType);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public PrepareDocumentForUpload(contexts: DataContext[] | DataContext, accountIdOrCode: string, documentId: string): Observable<ITypedServiceResponse<Models.Document>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}/Upload";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Document, request, context);
        });
        return request;
    }

    public ExpectANewDocument(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string,
        optionalParameters: {
            replace?: string
        }): Observable<ITypedServiceResponse<Models.Document>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/Upload";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.replace != null)
            params = params.set('replace', optionalParameters.replace);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Document, request, context);
        });
        return request;
    }

    public UploadDocument(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, documentId: string, uploadId: string,
        optionalParameters: {
            useAsyncAPI?: string,
            sendForExtraction?: string
        }): Observable<ITypedServiceResponse<Models.Document>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}/Upload";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('uploadId', uploadId);
        if (optionalParameters?.useAsyncAPI != null)
            params = params.set('useAsyncAPI', optionalParameters.useAsyncAPI);
        if (optionalParameters?.sendForExtraction != null)
            params = params.set('sendForExtraction', optionalParameters.sendForExtraction);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Document, request, context);
        });
        return request;
    }

    public RetrieveStatusOfDocumentUpload(contexts: DataContext[] | DataContext, accountIdOrCode: string, documentId: string): Observable<ITypedServiceResponse<Models.Document>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}/Upload/Status";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Document, request, context);
        });
        return request;
    }

    public RemoveDocument(idOfDeletedItem: string, contexts: DataContext[] | DataContext, accountIdOrCode: string, documentId: string): Observable<ITypedServiceResponse<Models.Document>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ModifyDocumentInfo(body: any, accountIdOrCode: string, documentId: string): Observable<Response> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public SetExtractionStatus(body: any, accountIdOrCode: string, documentId: string): Observable<Response> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}/SetExtractionStatus";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public Extract(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, documentId: string): Observable<ITypedServiceResponse<Models.Document>> {
        // Build the url
        var url = DocumentsService.baseUrl + "{accountIdOrCode}/{documentId}/Extract";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{documentId}", documentId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Document, request, context);
        });
        return request;
    }

}
import { Directive, TemplateRef, ViewContainerRef, Input, ChangeDetectorRef } from "@angular/core";
import { UserService } from "@Services/user-service";
import { PermissionsShared } from './sm-permissions-shared.class';

@Directive({
    selector: '[sm-has-permission]',
})
export class HasPermissionDirective {

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private userService: UserService,
                private cdRef: ChangeDetectorRef) {      
    }

    @Input('sm-has-permission') set hasPermission(permission: string) {
        this.userService.Permissions.subscribe(grantedPermissions => {
            this.viewContainer.clear();
            if (PermissionsShared.Has(grantedPermissions, permission)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            // This directive may be in an OnPush component. Force change detection
            this.cdRef.detectChanges();
        });    
    }
}

@Directive({
    selector: '[sm-has-all-permission]',
})
export class HasAllPermissionDirective {

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private userService: UserService,
                private cdRef: ChangeDetectorRef) {       
    }

    @Input('sm-has-all-permission') set hasAllPermission(permissions: string[]) {
        this.userService.Permissions.subscribe(grantedPermissions => {
            this.viewContainer.clear();
            if (PermissionsShared.HasAll(grantedPermissions, permissions)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            // This directive may be in an OnPush component. Force change detection
            this.cdRef.detectChanges();
        });
    }
}

@Directive({
    selector: '[sm-has-any-permission]',
})
export class HasAnyPermissionDirective {

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private userService: UserService) {      
    }

    @Input('sm-has-any-permission') set hasAnyPermission(permissions: string[]) {
        this.userService.Permissions.subscribe(grantedPermissions => {
            this.viewContainer.clear();
            if (PermissionsShared.HasAny(grantedPermissions, permissions)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        });
    }
}

@Directive({
    selector: '[sm-has-not-permission]',
})
export class HasNotPermissionDirective {

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private userService: UserService) {      
    }

    @Input('sm-has-not-permission') set hasNotPermission(permission: string) {
        this.userService.Permissions.subscribe(grantedPermissions => {
            this.viewContainer.clear();
            if (!PermissionsShared.Has(grantedPermissions, permission)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        });     
    }
}

@Directive({
    selector: '[sm-has-not-all-permission]',
})
export class HasNotAllPermissionDirective {

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private userService: UserService) {       
    }

    @Input('sm-has-not-all-permission') set hasAllPermission(permissions: string[]) {
        this.userService.Permissions.subscribe(grantedPermissions => {
            this.viewContainer.clear();
            if (!PermissionsShared.HasAll(grantedPermissions, permissions)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        });
    }
}

@Directive({
    selector: '[sm-has-not-any-permission]',
})
export class HasNotAnyPermissionDirective {

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private userService: UserService) {      
    }

    @Input('sm-has-not-any-permission') set hasAnyPermission(permissions: string[]) {
        this.userService.Permissions.subscribe(grantedPermissions => {
            this.viewContainer.clear();
            if (!PermissionsShared.HasAny(grantedPermissions, permissions)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        });
    }
}


import { Component, OnInit } from '@angular/core';
import { GlobalMessages, AppMessage } from '@Services/global-messages';

@Component({
    selector: 'sm-global-alerts',
    templateUrl: './sm-global-alerts.component.html',
    styleUrls: ['./sm-global-alerts.component.scss']
})
export class SmGlobalAlertsComponent implements OnInit {

    messages: AppMessage[];
    alerts: AppMessage[] = [];

    constructor(private service: GlobalMessages) { }

    ngOnInit() {
        this.service.values.subscribe(
            messages => this.messages = messages
        );
        this.service.last.subscribe(
            last => {
                if (last)
                    this.addMessage(last)
            }
        );
    }

    addMessage(message: AppMessage) {
        var self = this;
        this.alerts.push(message);
        if (message.alertDuration) {
            setTimeout(() => {
                self.closeAlert(message);
            }, message.alertDuration);
        }
    }

    closeAlert(alert: AppMessage) {
        const index: number = this.alerts.indexOf(alert);
        this.alerts.splice(index, 1);
    }

    performAction(alert: AppMessage) {
        alert.action.action();
        this.closeAlert(alert);
    }

}

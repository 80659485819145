import * as signalR from "@microsoft/signalr";
import { AuthService } from '@Services/auth-service';
import { UserService } from '@Services/user-service';
import { UserInfo } from './UserInfo';
import { LoggingService } from "@Services/logging-service";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { SyncHubStatusService } from "@Services/sync-hub-status-service";
import { environment } from "src/environments/environment";

export abstract class BaseHub {
    protected hubConnection: signalR.HubConnection;
    protected user: UserInfo;
    protected delayedGroupJoins: Set<string> = new Set<string>();

    protected hubName: string = this.constructor.name;
    protected route: string;

    private _isPolling: boolean = false;

    public get isPolling(): boolean {
        return this._isPolling;
    }

    constructor(
        protected userService: UserService,
        protected auth: AuthService,
        protected logger: LoggingService,
        protected statusService: SyncHubStatusService
    ) {
        let self = this;

        this.userService.User$.subscribe((u: any) => {
            if (u) {
                self.user = { tenantKey: u["@TenantKey"], userKey: u.Id };
                self.initialize();
            }

        })
    }

    protected initialize() { }
    protected mapMessageHandlers() { }

    protected onConnectionStartedCallback() { }
    protected onReconnectingCallback() {}
    protected onConnectionClosedCallback() { }

    protected onPollingStart() {}
    protected onPollingStop() {}

    protected getRandomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }    

    private startPolling() {
        if (!this.isPolling) {
            this._isPolling = true;
            this.onPollingStart();
        }
    }

    private stopPolling() {
        if (this.isPolling) {
            this._isPolling = false;
            this.onPollingStop();
        }
    }

    public isConnected(): boolean {
        return this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected;
    }

    private getAuthToken() {
        function getCookie(name: string): string | null {
            const cookieString = `; ${document.cookie}`;
            const cookies = cookieString.split(`; ${name}=`);

            if (cookies.length === 2)
                return cookies.pop()?.split(';').shift() || null;

            return null;
        }

        return getCookie(environment.access_token);
    }

    public startConnection(): void {
        let self = this;

        if (this.hubConnection)
            return;  // the connection is already defined

        // For local environments, we use proxy/sync and then let the proxy.conf.json file handle the proxying.
        var url = environment.syncHost;
        var baseUrl = `${url}/${this.route}`;
        this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(baseUrl, {
            accessTokenFactory: () => this.getAuthToken()
        })
        .withAutomaticReconnect([0, 2000, 10000, 20000])
        .configureLogging(signalR.LogLevel.Information)
        .build();

        this.hubConnection.onclose((error) => {
            self.logger.logEvent(`Sync Connection to ${self.hubName} Closed`, {"error": error});
            self.statusService.setHubState(self.hubName, self.hubConnection.state);
            // Wait 2 seconds and try to restart the connection with a new token
            setTimeout(() => { restart() }, 2  * 1000);
            self.startPolling();
            self.onConnectionClosedCallback();
        });

        this.hubConnection.onreconnecting((error) => {
            self.logger.logEvent(`Attempting to Reconnect To ${self.hubName}`, {"error": error});            
            self.statusService.setHubState(self.hubName, self.hubConnection.state);
            self.startPolling();
            self.onReconnectingCallback();
        });

        this.hubConnection.onreconnected((connectionId) => {
            self.statusService.setHubState(self.hubName, self.hubConnection.state);
            self.stopPolling();
            self.onConnectionStartedCallback();
            self.logger.logEvent(`Sync Service Reconnected to ${self.hubName}`, {"connectionId": connectionId});
        });

        self.mapMessageHandlers();

        start();

        function start(): void {
            self.hubConnection.baseUrl = baseUrl;
            self.hubConnection.start()
                .then(() => {
                    self.logger.logInfo(`Connection to ${self.hubName} started.`);
                    self.statusService.setHubState(self.hubName, self.hubConnection.state);
                    self.initialize();
                    self.stopPolling();
                    self.onConnectionStartedCallback();
                })
                .catch((err) => {
                    let error = new Error(`Error while starting connection to ${self.hubName}`);
                    if (err) {
                        self.logger.logException(error, SeverityLevel.Error, {originalError: err});
                    } else {
                        self.logger.logException(error, SeverityLevel.Error);
                    } 
                    self.statusService.setHubState(self.hubName, signalR.HubConnectionState.Disconnected);
                    // Try to reconnect in 5 minutes
                    setTimeout(() => { restart() }, 5 * 60 * 1000);
                    self.startPolling();
                    self.onConnectionClosedCallback();
                });
        }

        
        // A restart is not the same as a reconnect. Because we often get a 401 when trying to reconnect, we need
        // a new, refreshed token. So instead of trying to reconnect to the same hub, start a new connection. 
        function restart(): void {
            self.stopPolling();
            self.hubConnection = null;
            self.startConnection();
        }
    }

    public join(id: string): boolean {
        if (!id) {
            return false;
        } else if (!this.isConnected() || !this.user) {
            this.delayedGroupJoins.add(id);
            return false;
        }

        this.delayedGroupJoins.delete(id);
        this.hubConnection.invoke('Join', id);
        return true;
    }

    public leave(id: string) {
        if (!id) {
            return false;
        } else if (!this.isConnected() || !this.user) {
            this.delayedGroupJoins.delete(id);
            return false;
        }

        this.hubConnection.invoke('Leave', id);
        return true;
    }

}
<unity-header [view]="'Work'">
    <ng-container main >
        <account-search></account-search>
        <div class="title_bar--item title_bar--new">
            <new-business-button></new-business-button>
        </div>
    </ng-container>
</unity-header>

<div class="main_content">
    <router-outlet></router-outlet>
    <unity-loading-indicator *ngIf="loading" display="overlay"></unity-loading-indicator>
</div>
import { LoadingService } from '@Services/loading-service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@Services/auth-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public loading = false;

    constructor(public authService: AuthService,
        private router: Router,
        private loadingService: LoadingService) {
        this.loadingService.loading.subscribe(l => {
            this.loading = l;
        });
    }
}